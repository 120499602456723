<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="libraries"
          :search="search"
          :total-pages.sync="totalPages"
          :filters.sync="filters"
          :is-loading="isLoadingData"
          title="Perpustakaan Berbayar"
          subtitle="Halaman untuk menambahkan perpustakaan"
          add-btn="Tambah Data"
          :filter-return-object="true"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @category-filtered="handleCategoryFilter"
          @subcategory-filtered="handleSubcategoryFilter"
          @subsubcategory-filtered="handleSubsubcategoryFilter"
          @subsubsubcategory-filtered="handleSubsubsubcategoryFilter"
          @level-filtered="handleLevelFilter"
          @year-filtered="handleYearFilter"
          @delete-item="confirmDestroy"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @reset-filter="resetFilter"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="1080"
      :is-loading-button="isLoadingButton"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Buku': 'Edit Buku'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="library.name"
                label="Judul Buku"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="fPrice"
                label="Harga"
                dense
                type="text"
                outlined
                onkeypress="return /[0-9]/i.test(event.key)"
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="library.description"
                label="Deskripsi Buku"
                dense
                outlined
              ></v-textarea>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-card
              outlined
              class="pa-5 mb-7"
            >
              <div>
                <h2>Kategori</h2>
                <p class="text-body-2">
                  Kategori untuk buku yang akan dibuat
                </p>
              </div>
              <v-row class="mt-8 px-0">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <div>
                    <v-autocomplete
                      v-model="library.category.uuid"
                      clearable
                      :items="categories"
                      label="Kategori"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      @change="changeSubcategories"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <div>
                    <v-autocomplete
                      v-model="sub_category.uuid"
                      clearable
                      :items="subcategories"
                      label="Sub Kategori"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      @change="changeSubsubcategories"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <div>
                    <v-autocomplete
                      v-model="sub_sub_category.uuid"
                      clearable
                      :items="subsubcategories"
                      label="Sub Kategori"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      @change="changeSubsubsubcategories"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <div>
                    <v-autocomplete
                      v-model="sub_sub_sub_category.uuid"
                      clearable
                      :items="subsubsubcategories"
                      label="Sub Kategori"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                      @change="changeSubsubsubsubcategories"
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <div>
                    <v-autocomplete
                      v-model="sub_sub_sub_sub_category.uuid"
                      clearable
                      :items="subsubsubsubcategories"
                      label="Sub Kategori"
                      item-text="name"
                      item-value="uuid"
                      dense
                      outlined
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
              </v-row>
              <div class="mb-6">
                <a
                  class="text-decoration-none text-button"
                  @click.prevent="listSubCategory"
                >Simpan</a>
              </div>
              <template>
                <v-expansion-panels
                  v-for="(temp, index) in tempCategory"
                  :key="temp.uuid"
                >
                  <v-expansion-panel class="d-flex justify-space-between align-center mb-2 pa-4">
                    <div class="d-flex justify-start align-center">
                      <div style="width: 50px">
                        {{ index + 1 }}.
                      </div>
                      <div
                        v-for="(temps, index) in temp"
                        :key="index"
                        class="mr-5"
                      >
                        {{ firstParent(temps) }}
                      </div>
                    </div>

                    <div
                      class="cursor-pointer"
                      @click.prevent="deleteTemp(index)"
                    >
                      <v-icon>{{ icons.mdiClose }}</v-icon>
                    </div>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <!-- <v-row class="mb-2 px-0">
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <template>
                    <v-simple-table
                      class="category-table"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              No
                            </th>
                            <th class="text-center text-uppercase">
                              Kategori
                            </th>
                            <th class="text-center text-uppercase">
                              Sub Kategori
                            </th>
                            <th class="text-center text-uppercase">
                              Sub Kategori
                            </th>
                            <th class="text-center text-uppercase">
                              Sub Kategori
                            </th>
                            <th class="text-center text-uppercase">
                              Sub Kategori
                            </th>
                            <th class="text-center text-uppercase">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="temp in tempCategory"
                            :key="temp.uuid"
                          >
                            <th>
                              {{ index+1 }}
                            </th>
                            <th
                              v-for="(temps, index) in temp"
                              :key="index"
                              class="text-uppercase"
                            >
                              {{ firstParent(temps) }}
                            </th>
                            <th
                              class="text-center text-uppercase"
                              @click.prevent="deleteTemp(index)"
                            >
                              X
                            </th>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </v-col>
              </v-row> -->
            </v-card>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="library.level.uuid"
                clearable
                label="Tingkat"
                :items="levels"
                item-text="name"
                item-value="uuid"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="library.year"
                label="Tahun Terbit"
                dense
                type="number"
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="library.writer.uuid"
                clearable
                :items="writers"
                label="Penulis"
                item-text="name"
                item-value="uuid"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="library.publisher.uuid"
                clearable
                :items="publishers"
                label="Penerbit"
                item-text="name"
                item-value="uuid"
                dense
                outlined
              >
              </v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div class="mb-5">
              <a
                class="text-decoration-none text-button"
                @click.prevent="showFormWriter"
              >Tambah Penulis</a>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div class="mb-5">
              <a
                class="text-decoration-none text-button"
                @click.prevent="showFormPublisher"
              >Tambah Penerbit</a>
            </div>
          </v-col>
          <v-col
            cols="12"
            class=""
          >
            <FileInput
              v-model="initialFile"
              label="Thumbnail"
              outlined
              :prependicon="icons.mdiImageMultipleOutline"
              dense
              @input="getFile"
            ></FileInput>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <h2>Tipe Buku</h2>
              <p class="text-body-2">
                Pilih Tipe
              </p>
            </div>
            <div>
              <!-- <v-radio-group
                v-for="(type,index) in types"
                :key="index"
                row
              >
                <v-radio
                  v-model="radio"
                  class="d-inline"
                  :label="`${type.icon}`"
                >
                </v-radio>
              </v-radio-group> -->

              <v-radio-group
                v-model="radioType"
                row
              >
                <div
                  v-for="(type,index) in types"
                  :key="index"
                  class="d-flex align-center me-4"
                >
                  <v-radio
                    class="mr-0"
                    :value="type.value"
                    @change="handleRadio(type.value)"
                  ></v-radio>
                  <v-icon
                    class="me-2"
                    :color="radioType === type.value ? 'primary' : ''"
                  >
                    {{ type.icon }}
                  </v-icon>
                  <span :class="radioType === type.value ? 'primary--text' : ''">{{ type.title }}</span>
                </div>
              </v-radio-group>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <h2>Chapter Buku</h2>
              <p class="text-body-2">
                Harap isi chapter buku
              </p>
              <v-card outlined>
                <v-card-text>
                  <div>
                    <!-- <div
                      class=""
                    >
                      <h2 class="mb-2">
                        Chapter {{ numberChapterDefault }}
                      </h2>
                      <div>
                        <v-text-field
                          v-model="library.chapter_name"
                          label="Judul Chapter"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                      <div>
                        <v-textarea
                          v-model="library.chapter_description"
                          label="Deskripsi Chapter"
                          outlined
                        >
                        </v-textarea>
                      </div>
                      <div>
                        <v-text-field
                          v-model="library.chapter_content"
                          label="URL Konten"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                    </div> -->
                    <div
                      v-for="(chapter,index) in chapters"
                      :key="index"
                      class="mb-4"
                    >
                      <div class="d-flex justify-space-between align-center">
                        <div>
                          <h2 class="mb-2">
                            Chapter {{ numberChapter + (index+1) }}
                          </h2>
                        </div>
                        <div>
                          <v-btn
                            v-if="chapter.length > 1 || dialog === 'add'"
                            small
                            icon
                            color="primary"
                            @click="deleteChapter(index)"
                          >
                            <v-icon>
                              {{ icons.mdiCloseCircleOutline }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                      <div>
                        <v-text-field
                          v-model="chapter[index].name"
                          label="Judul Chapter"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                      <div>
                        <v-textarea
                          v-model="chapter[index].description"
                          label="Deskripsi Chapter"
                          outlined
                        >
                        </v-textarea>
                      </div>
                      <div>
                        <v-text-field
                          v-model="chapter[index].content"
                          label="URL Konten"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div class="mt-4">
                    <a
                      class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-sm"
                      @click="addChapter"
                    >Tambah Chapter Baru</a>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <!-- <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="library.video"
                label="URL Video"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="library.ebook"
                label="URL E-Book"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col> -->
          <v-col
            cols="12"
            class="py-0"
          >
            <template>
              <div>
                <div class="d-flex demo-space-x flex-column flex-md-row flex-lg-row flex-xl-row flex-wrap">
                  <div>
                    <p class="text-subtitle-1 ma-0">
                      Tambahkan Buku ke:
                    </p>
                  </div>
                  <v-checkbox
                    label="Sekolah"
                    value="Sekolah"
                  ></v-checkbox>
                  <v-checkbox
                    label="Umum"
                    value="Umum"
                  ></v-checkbox>
                  <v-checkbox
                    label="Desa"
                    value="Desa"
                  ></v-checkbox>
                  <v-checkbox
                    label="Pusda"
                    value="Pusda"
                  ></v-checkbox>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </template>
      <!-- <template v-slot:footer>
        <v-btn
          v-if="dialog == 'add'"
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="isLoadingButton"
          @click="add()"
        >
          Tambah
        </v-btn>
        <v-btn
          v-if="dialog == 'edit'"
          color="primary"
          large
          :loading="isLoadingButton"
          :disabled="isLoadingButton"
          @click="update(library.uuid)"
        >
          Update
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDialog = false"
        >
          Batal
        </v-btn>
      </template> -->
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Buku"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
    <ModalDialog
      :visible="modalDialogWriterAndPublisher"
      :width="600"
      :header="type === 'writer' ? 'Tambah Penulis': 'Tambah Penerbit'"
      text-button="Tambah"
      :is-loading-button="isLoadingButton"
      @handler-button="handlerSubmit"
      @close-modal="modalDialogWriterAndPublisher = false"
      @close-button="modalDialogWriterAndPublisher = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-if="type =='writer'"
            v-model="writer.name"
            label="Nama Penulis"
            dense
            outlined
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-if="type =='publisher'"
            v-model="publisher.name"
            label="Nama Penerbit"
            dense
            outlined
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import currencyToIntFormat from '@/utils/currency/currencyToIntFormat'
import toCurrencyFormat from '@/utils/currency/toCurrencyFormat'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import FileInput from '@/views/components/FileInput.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import {
  mdiBook,
  mdiBookMusic,
  mdiBookPlay,
  mdiClose,
  mdiCloseCircleOutline,
  mdiImageMultipleOutline,
  mdiMapMarker,
} from '@mdi/js'

export default {
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    FileInput,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      radio: [],
      service: 'library',
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      modalDialog: false,
      editFormDialog: false,
      confirmDialog: false,
      modalDialogWriterAndPublisher: false,
      dialog: '',
      page: 1,
      totalPages: 0,
      totalItems: 0,
      search: '',
      categories: [],
      subcategories: [],
      subsubcategories: [],
      subsubsubcategories: [],
      subsubsubsubcategories: [],
      writers: [],
      writer: {
        name: '',
      },
      type: '',
      publishers: [],
      publisher: {
        name: '',
      },
      libraries: [],
      levels: [],
      multiCategories: [],
      sub_category: {
        uuid: '',
      },
      sub_sub_category: {
        uuid: '',
      },
      sub_sub_sub_category: {
        uuid: '',
      },
      sub_sub_sub_sub_category: {
        uuid: '',
      },
      tempCategory: [],
      library: {
        uuid: '',
        name: '',
        category: {
          uuid: '',
        },
        sub_category: {
          uuid: '',
        },
        sub_sub_category: {
          uuid: '',
        },
        writer: {
          uuid: '',
        },
        publisher: {
          uuid: '',
        },
        level: {
          uuid: '',
        },
        price: 0,
        description: '',
        type: '',
        chapter_name: [],
        chapter_description: [],
        chapter_content: [],
        thumbnail: null,
      },
      icons: {
        mdiMapMarker,
        mdiImageMultipleOutline,
        mdiClose,
        mdiBook,
        mdiBookMusic,
        mdiBookPlay,
        mdiCloseCircleOutline,
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Judul Buku', value: 'name' },
        { text: 'Harga Buku', value: 'price' },
        { text: 'Kategori', value: 'categoryList' },
        { text: 'Sub Kategori', value: 'subcategoryList' },
        { text: 'Sub Kategori', value: 'subsubcategoryList' },
        { text: 'Sub Kategori', value: 'subsubsubcategoryList' },
        { text: 'Sub Kategori', value: 'subsubsubsubcategoryList' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Tingkat', value: 'level.name' },
        { text: 'Tahun Terbit', value: 'year' },
        { text: 'Penulis', value: 'writer.name' },
        { text: 'Penerbit', value: 'publisher.name' },
        { text: 'Status', value: 'statusLibrary' },
        { text: 'Aksi', value: 'actions' },
      ],
      filters: [],
      filterQuery: {
        category: '',
        sub_category: '',
        sub_sub_category: '',
        sub_sub_sub_category: '',
        sub_sub_sub_sub_category: '',
        sub_sub_sub_sub_sub_category: '',
        year: '',
        level: '',
        library: '',
      },
      yearFilter: [],
      levelFilter: [],
      formValid: false,
      types: [
        {
          index: 1,
          icon: mdiBookPlay,
          title: 'Video Book',
          value: 'video',
        },
        {
          index: 2,
          icon: mdiBookMusic,
          title: 'Audio Book',
          value: 'audio',
        },
        {
          index: 3,
          icon: mdiBook,
          title: 'E Book',
          value: 'ebook',
        },
      ],
      radioType: '',
      chapters: [],
      chapterContent: [],
      numberChapterDefault: 1,
      numberChapter: 0,
      chapter: {},
      urlContent: '',
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.library.thumbnail === 'string') {
          return new File([this.library.thumbnail], this.library.thumbnail, { type: 'text/plain' })
        }

        return this.library.thumbnail
      },
      set() {
        return this.library.thumbnail
      },
    },
    fPrice: {
      get() {
        const formatedPrice = toCurrencyFormat(this.library.price)

        return formatedPrice === 'NaN' ? null : formatedPrice
      },
      set(newValue) {
        const formatedPrice = toCurrencyFormat(newValue)
        this.library.price = formatedPrice === 'NaN' ? null : formatedPrice
      },
    },
  },
  watch: {
    library: {
      handler() {
        const valid = []
        const requiredField = [
          'name',
          'category',
          'writer',
          'publisher',
          'level',
          'price',
          'description',
          'year',
          'video',
          'audio',
          'ebook',
        ]
        Object.entries(this.library).forEach(([key, item]) => {
          if (requiredField.includes(key)) {
            if (requiredField.includes(key) && Object.keys(item).length !== 0) {
              valid.push(!!(item && Object.keys(item).length))
            } else {
              valid.push(!!(item && item.length))
            }
          }
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listLibrary({ ...this.filterQuery })
      },
    },
  },
  mounted() {
    this.isLoadingTable = true
    this.listLevel()
    this.listLibrary()
    this.listCategory()
    this.listWriter()
    this.listPublisher()
    this.insertFiltersOption()

    this.isLoadingTable = false
  },
  methods: {
    handleRadio(value) {
      this.radioType = value
    },
    addChapter() {
      this.chapterContent = [
        ...this.chapterContent,
        {
          name: '',
          description: '',
          content: '',
        },
      ]
      this.chapters.push(this.chapterContent)
    },
    deleteChapter(index) {
      this.chapters.splice(index, 1)

      this.chapterContent.splice(index, 1)
    },
    firstParent(temp) {
      return temp?.name
    },
    deleteTemp(index) {
      this.multiCategories.splice(index, 1)
      this.tempCategory.splice(index, 1)
    },
    resetForm() {
      this.library.name = ''
      this.library.category.uuid = ''
      this.sub_category.uuid = ''
      this.sub_sub_category.uuid = ''
      this.sub_sub_sub_category.uuid = ''
      this.sub_sub_sub_sub_category.uuid = ''
      this.library.description = ''
      this.library.level.uuid = ''
      this.library.writer.uuid = ''
      this.library.publisher.uuid = ''
      this.library.year = ''
      this.writer.name = ''
      this.library.thumbnail = null
      this.library.price = 0
      this.radioType = ''
      this.chapters = []
      this.chapterContent = []
    },
    resetCategory() {
      this.library.category.uuid = ''
      this.sub_category.uuid = ''
      this.sub_sub_category.uuid = ''
      this.sub_sub_sub_category.uuid = ''
      this.sub_sub_sub_sub_category.uuid = ''
    },
    showFormAdd() {
      this.multiCategories = []
      this.tempCategory = []
      this.chapters = []
      this.resetForm()
      this.dialog = 'add'
      this.addChapter()
      this.listCategory()
      this.listWriter()
      this.listPublisher()
      this.listLevel()
      this.modalDialog = true
    },
    showFormWriter() {
      this.type = 'writer'
      this.modalDialogWriterAndPublisher = true
    },
    showFormPublisher() {
      this.type = 'publisher'
      this.modalDialogWriterAndPublisher = true
    },
    async addWriter() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('writer', this.writer).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add writer has successfully',
            color: 'success',
          })
          this.writer = data.data
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
      this.modalDialogWriterAndPublisher = false
      this.writer.name = ''
      this.listWriter()
    },
    async addPublisher() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add('publisher', this.publisher).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add publisher has successfully',
            color: 'success',
          })
          this.publisher = data.data
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
      this.modalDialogWriterAndPublisher = false
      this.publisher.name = ''
      this.publisher.year = ''
      this.listPublisher()
    },
    handlerSubmit() {
      if (this.type === 'writer') {
        this.addWriter()
      } else if (this.type === 'publisher') {
        this.addPublisher()
      }
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    async listLibrary(params = { ...this.filterQuery }) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, { ...params, page: this.page, status: 'paid' }).then(
        async ({ data }) => {
          this.libraries = await data.data.map((libraries, index) => {
            libraries.subcategory = []
            libraries.subsubcategory = []
            libraries.subsubsubcategory = []
            libraries.subsubsubsubcategory = []
            libraries.category.map((data, key) => {
              let categoryMap = []
              categoryMap.push(
                libraries.category[key].parent_category?.parent_category?.parent_category?.parent_category,
              )
              categoryMap.push(libraries.category[key].parent_category?.parent_category?.parent_category)
              categoryMap.push(libraries.category[key].parent_category?.parent_category)
              categoryMap.push(libraries.category[key].parent_category)
              categoryMap.push(libraries.category[key])
              categoryMap = categoryMap.filter(item => item)
              categoryMap.forEach((item, index) => {
                if (index === 0) libraries.category[key] = item
                else libraries[`${'sub'.repeat(index)}category`].push(item)
              })

              return {
                ...libraries,
                index: index + 1,
              }
            })

            return {
              ...libraries,
              index: index + 1,
              typeLibrary: {
                audio: libraries.audio,
                video: libraries.video,
                ebook: libraries.ebook,
              },
            }
          })

          this.totalItems = data.meta.total
          this.totalPages = data.meta.last_page
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingData = false
    },
    getFile(data) {
      this.library.thumbnail = data
    },
    async getChildCategory(uuid) {
      await this.$services.ApiServices.get('category', uuid).then(
        ({ data }) => {
          let categoryMap = []
          categoryMap.push(data.data?.parent_category?.parent_category?.parent_category?.parent_category)
          categoryMap.push(data.data?.parent_category?.parent_category?.parent_category)
          categoryMap.push(data.data?.parent_category?.parent_category)
          categoryMap.push(data.data?.parent_category)
          categoryMap.push(data.data)
          categoryMap = categoryMap.filter(item => item)
          this.tempCategory.push(categoryMap)
        },
        err => console.error(err),
      )
    },
    async listCategory() {
      await this.$services.ApiServices.list('category', { per_page: 'all', parent: '1' }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
    },
    async listLevel() {
      await this.$services.ApiServices.list('level', { per_page: 'all' }).then(
        ({ data }) => {
          this.levels = data.data
        },
        err => console.error(err),
      )
    },
    async listWriter() {
      await this.$services.ApiServices.list('writer', { per_page: 'all' }).then(
        ({ data }) => {
          this.writers = data.data
        },
        err => console.error(err),
      )
    },
    async listPublisher() {
      await this.$services.ApiServices.list('publisher', { per_page: 'all' }).then(
        ({ data }) => {
          this.publishers = data.data
        },
        err => console.error(err),
      )
    },
    listSubCategory() {
      if (this.sub_sub_sub_sub_category.uuid) {
        this.library.category.uuid = this.sub_sub_sub_sub_category.uuid
      } else if (this.sub_sub_sub_category.uuid) {
        this.library.category.uuid = this.sub_sub_sub_category.uuid
      } else if (this.sub_sub_category.uuid) {
        this.library.category.uuid = this.sub_sub_category.uuid
      } else if (this.sub_category.uuid) {
        this.library.category.uuid = this.sub_category.uuid
      }
      if (this.library.category.uuid === '') {
        return this.showSnackbar({
          text: 'category cannot be null',
          color: 'error',
        })
      }
      if (this.multiCategories.includes(this.library.category.uuid)) {
        return this.showSnackbar({
          text: 'cannot add same category',
          color: 'error',
        })
      }
      this.multiCategories.push(this.library.category.uuid)
      // this.multiCategories = this.multiCategories.filter(item => item !== this.library.category.uuid)
      this.getChildCategory(this.library.category.uuid)
      this.resetCategory()

      return true
    },
    async add() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.library.name)
      this.formData.append('writer_uuid', this.library.writer.uuid)
      this.formData.append('level_uuid', this.library.level.uuid)
      this.formData.append('publisher_uuid', this.library.publisher.uuid)
      this.formData.append('description', this.library.description)
      this.formData.append('price', currencyToIntFormat(this.library.price))
      this.formData.append('status', 'paid')
      this.formData.append('year', this.library.year)
      this.formData.append('type', this.radioType)
      this.multiCategories.forEach(item => {
        this.formData.append('category_uuid[]', item)
      })
      this.chapterContent.forEach(async (item, index) => {
        this.formData.append('chapter_name[]', item.name)
        this.formData.append('chapter_description[]', item.description)
        this.formData.append('chapter_content[]', item.content)
      })

      this.formData.append('thumbnail', this.library.thumbnail)
      if (this.library.thumbnail === null) {
        this.formData.delete('thumbnail')
      }
      await this.$services.ApiServices.add(this.service, this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
      this.modalDialog = false
      await this.listLibrary()
      this.resetForm()
      this.resetFilter()
    },
    async showFormEdit(uuid) {
      this.multiCategories = []
      this.resetCategory()
      this.tempCategory = []
      this.dialog = 'edit'
      this.library.uuid = uuid
      this.modalDialog = true
      this.chapters = []
      await this.$services.ApiServices.get(this.service, uuid).then(
        async ({ data }) => {
          this.library = { ...data.data }

          data.data.library_chapter.forEach(data => {
            const a = data.content.split('id=')
            const b = a[1].split('&')
            this.urlContent = [...b].shift()
            this.chapterContent = [
              ...this.chapters,
              {
                uuid: data.uuid,
                name: data.name,
                description: data.description,
                content: this.urlContent,
              },
            ]

            this.chapters.push(this.chapterContent)
          })
          if (data.data.type === 'audio') {
            this.radioType = 'audio'
          } else if (data.data.type === 'video') {
            this.radioType = 'video'
          } else if (data.data.type === 'ebook') {
            this.radioType = 'ebook'
          }
          if (data.data.level === null) this.library.level = { uuid: '' }
          if (data.data.writer === null) this.library.writer = { uuid: '' }
          if (data.data.publisher === null) this.library.publisher = { uuid: '' }

          data.data.category.map((cat, index) => {
            let categoryMap = []
            categoryMap.push(
              data.data.category[index]?.parent_category?.parent_category?.parent_category?.parent_category,
            )
            categoryMap.push(data.data.category[index]?.parent_category?.parent_category?.parent_category)
            categoryMap.push(data.data.category[index]?.parent_category?.parent_category)
            categoryMap.push(data.data.category[index]?.parent_category)
            categoryMap.push(data.data.category[index])
            categoryMap = categoryMap.filter(item => item)

            return this.tempCategory.push(categoryMap)
          })

          await this.listCategoryEditSession(data.data.category)
        },
        err => console.error(err),
      )
    },
    async update() {
      this.isLoadingButton = true
      this.formData = new FormData()

      this.formData.append('name', this.library.name)
      this.formData.append('writer_uuid', this.library.writer.uuid)
      this.formData.append('level_uuid', this.library.level.uuid)
      this.formData.append('publisher_uuid', this.library.publisher.uuid)
      this.formData.append(
        'description',
        this.library.description === null || this.library.description === '' ? '-' : this.library.description,
      )
      this.formData.append('year', this.library.year)
      this.formData.append('status', 'paid')
      this.formData.append('type', this.radioType)
      this.multiCategories.forEach(item => {
        this.formData.append('category_uuid[]', item)
      })
      this.chapterContent.forEach((item, index) => {
        if (item.length) {
          this.formData.append('chapter_uuid[]', item[item.length - 1].uuid)
          this.formData.append('chapter_name[]', item[item.length - 1].name)
          this.formData.append('chapter_description[]', item[item.length - 1].description)
          this.formData.append('chapter_content[]', item[item.length - 1].content)
        } else {
          if (item.uuid !== undefined) {
            this.formData.append('chapter_uuid[]', item.uuid)
          }
          this.formData.append('chapter_name[]', item.name)
          this.formData.append('chapter_description[]', item.description)
          this.formData.append('chapter_content[]', item.content)
        }
      })
      this.formData.append('thumbnail', this.library.thumbnail)
      if (this.library.thumbnail === null || typeof this.library.thumbnail === 'string') {
        this.formData.delete('thumbnail')
      }
      await this.$services.ApiServices.update(this.service, this.formData, this.library.uuid).then(
        ({ data }) => {
          this.resetForm()
          this.modalDialog = false
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listLibrary()
    },
    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.library.uuid = uuid
    },
    async destroy() {
      this.isLoadingButton = true

      await this.$services.ApiServices.destroy(this.service, this.library.uuid).then(
        ({ data }) => {
          this.listLibrary()
          this.confirmDialog = false
          this.isLoadingButton = false
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    async insertFiltersOption() {
      const categoryFilters = []
      const subcategoryFilters = []
      const subsubcategoryFilters = []
      const subsubsubcategoryFilters = []

      // eslint-disable-next-line no-plusplus
      for (let i = 1990; i <= 2022; i++) {
        this.yearFilter.push(i)
      }

      // await this.$services.ApiServices.list('category', { per_page: 'all' }).then(
      //   ({ data }) => {
      //     categoryFilters = data.data
      //   },
      //   err => console.error(err),
      // )

      // await this.$services.ApiServices.list('level', { per_page: 'all' }).then(
      //   ({ data }) => {
      //     this.levelFilter = data.data
      //   },
      //   err => console.error(err),
      // )

      this.filters = [
        {
          label: 'Category',
          emitName: 'category-filtered',
          data: this.categories,
        },
        {
          label: 'Subcategory',
          emitName: 'subcategory-filtered',
          data: subcategoryFilters,
        },
        {
          label: 'Subsubcategory',
          emitName: 'subsubcategory-filtered',
          data: subsubcategoryFilters,
        },
        {
          label: 'Subsubsubcategory',
          emitName: 'subsubsubcategory-filtered',
          data: subsubsubcategoryFilters,
        },
        {
          label: 'Level',
          emitName: 'level-filtered',
          data: this.levels,
        },
        {
          label: 'Year',
          emitName: 'year-filtered',
          data: this.yearFilter,
        },
      ]
    },
    async handleCategoryFilter(event) {
      this.filterQuery.category = event.uuid
      this.filters = this.filters.filter(
        item => !['Subcategory', 'Subsubcategory', 'Subsubsubcategory', 'Level', 'Year'].includes(item.label),
      )
      this.filters = [
        ...this.filters,
        {
          label: 'Subcategory',
          emitName: 'subcategory-filtered',
          data: event.sub_categories,
        },
        {
          label: 'Subsubcategory',
          emitName: 'subsubcategory-filtered',
          data: [],
        },
        {
          label: 'Subsubsubcategory',
          emitName: 'subsubsubcategory-filtered',
          data: [],
        },
        {
          label: 'Level',
          emitName: 'level-filtered',
          data: this.levelFilter,
        },
        {
          label: 'Year',
          emitName: 'year-filtered',
          data: this.yearFilter,
        },
      ]
      this.listLibrary(this.filterQuery)
    },
    async handleSubcategoryFilter(event) {
      this.filterQuery.category = event.uuid
      this.filters = this.filters.filter(
        item => !['Subsubcategory', 'Subsubsubcategory', 'Level', 'Year'].includes(item.label),
      )
      this.filters = [
        ...this.filters,
        {
          label: 'Subsubcategory',
          emitName: 'subsubcategory-filtered',
          data: event.sub_categories,
        },
        {
          label: 'Subsubsubcategory',
          emitName: 'subsubsubcategory-filtered',
          data: [],
        },
        {
          label: 'Level',
          emitName: 'level-filtered',
          data: this.levelFilter,
        },
        {
          label: 'Year',
          emitName: 'year-filtered',
          data: this.yearFilter,
        },
      ]
      this.listLibrary(this.filterQuery)
    },
    async handleSubsubcategoryFilter(event) {
      this.filterQuery.category = event.uuid
      this.filters = this.filters.filter(item => !['Subsubsubcategory', 'Level', 'Year'].includes(item.label))
      this.filters = [
        ...this.filters,
        {
          label: 'Subsubsubcategory',
          emitName: 'subsubsubcategory-filtered',
          data: event.sub_categories,
        },
        {
          label: 'Level',
          emitName: 'level-filtered',
          data: this.levelFilter,
        },
        {
          label: 'Year',
          emitName: 'year-filtered',
          data: this.yearFilter,
        },
      ]
      this.listLibrary(this.filterQuery)
    },
    async handleSubsubsubcategoryFilter(event) {
      this.filterQuery.category = event.uuid
      this.listLibrary(this.filterQuery)
    },
    async handleYearFilter(event) {
      this.filterQuery.year = event
      this.listLibrary(this.filterQuery)
    },
    async handleLevelFilter(event) {
      this.filterQuery.level = event.uuid
      this.listLibrary(this.filterQuery)
    },
    changeSubcategories() {
      const category = this.categories.filter(item => item.uuid === this.library.category.uuid)
      this.subcategories = category.length > 0 ? [...category[0].sub_categories] : []
      this.sub_category.uuid = ''
      this.subsubcategories = []
    },
    changeSubsubcategories() {
      const subcategory = []
      this.categories.forEach(item => {
        item.sub_categories.forEach(sub => {
          if (sub.uuid === this.sub_category.uuid) subcategory.push(sub)
        })
      })
      this.sub_sub_category.uuid = ''
      this.subsubcategories = subcategory.length > 0 ? [...subcategory[0].sub_categories] : []
    },
    changeSubsubsubcategories() {
      const subcategory = []
      this.subcategories.forEach(item => {
        item.sub_categories.forEach(sub => {
          if (sub.uuid === this.sub_sub_category.uuid) subcategory.push(sub)
        })
      })
      this.sub_sub_sub_category.uuid = ''
      this.subsubsubcategories = subcategory.length > 0 ? [...subcategory[0].sub_categories] : []
    },
    changeSubsubsubsubcategories() {
      const subcategory = []
      this.subsubcategories.forEach(item => {
        item.sub_categories.forEach(sub => {
          if (sub.uuid === this.sub_sub_sub_category.uuid) subcategory.push(sub)
        })
      })
      this.sub_sub_sub_sub_category.uuid = ''
      this.subsubsubsubcategories = subcategory.length > 0 ? [...subcategory[0].sub_categories] : []
    },
    async listCategoryEditSession(cat) {
      await this.listCategory()
      this.categories.forEach(category => {
        this.subcategories = [...this.subcategories, ...category.sub_categories]

        category.sub_categories.forEach(sub => {
          this.subsubcategories = [...this.subsubcategories, ...sub.sub_categories]

          sub.sub_categories.forEach(sub => {
            this.subsubsubcategories = [...this.subsubsubcategories, ...sub.sub_categories]

            sub.sub_categories.forEach(sub => {
              this.subsubsubsubcategories = [...this.subsubsubsubcategories, ...sub.sub_categories]
            })
          })
        })
      })

      cat.map((category, index) => this.multiCategories.push(category.uuid))
    },
    searchHandler(data) {
      this.filterQuery.library = data
      this.page = 1
      this.listLibrary({ ...this.filterQuery })
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    resetFilter() {
      this.filterQuery = {
        category: '',
        sub_category: '',
        sub_sub_category: '',
        sub_sub_sub_category: '',
        sub_sub_sub_sub_category: '',
        sub_sub_sub_sub_sub_category: '',
        year: '',
        level: '',
        library: '',
      }
      this.insertFiltersOption()
      this.listLibrary()
    },
  },
}
</script>

<style scoped>
.category-table {
  height: auto;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: scroll;
}
</style>
